.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'PT Sans', sans-serif;
  margin-bottom: 50px;
  color: #372a28;
}

.intro {
  height: 500px;
  width: 500px;
  /* margin-left: 10px; */
}

.linkedin {
  height: 100px;
  width: 100px;
}

article {
  width: 500px;
  text-align: center;
}

.social {
  height: 50px;
  width: 50px;
}

.links li {
  display: inline;
  margin: 10px;
  align-content: center;
  padding-right: 20px;
  padding-top: 0px;
}

.project-photo {
  width: 500px;
  border: 5px solid #7589b3;
  border-radius: 27px;
}

.page-break {
  height: 2px;
}

details summary {
  /* font-weight: bold; */
  padding-top: 5px;
  width: 500px;
  text-align: center;
  color: #372a28;
}

details {
  width: 500px;
  text-align: center;
  color: #372a28;
}

h1 {
  font-family: 'Nunito', sans-serif;
  font-weight: bolder;
  margin-top: 50px;
  color: #f2c256;
}

h2 {
  font-family: 'Nunito', sans-serif;
  font-size: 25px;
  font-weight: bolder;
  margin-top: 50px;
  color: #b78b3c;
}

.projects {
  margin-bottom: 0;
}

.tech-stack {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 600px;
  text-align: center;
  justify-content: center;
}

li {
  list-style: none;
}

.list-header {
  font-weight: bold;
}

.skincode {
  margin-top: 20px;
}

.projectlink {
  font-family: 'Nunito', sans-serif;
  font-weight: bolder;
  margin-top: 50px;
  color: #372a28;
}

.quick-links {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.quick-links a {
  padding: 10px;
  color: #7589b3;
  font-weight: bolder;
  padding-bottom: 0;
  font-size: 25px;
}

a {
  text-decoration: none;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
}

.email {
  margin-bottom: 20px;
  color: #372a28;
}

.back-to-top {
  margin-top: 20px;
  color: #7589b3;
  font-weight: bolder;
  font-size: 20px;
}

.katilyn {
  height: 200px;
  border-radius: 50px;
}

.social-small {
  height: 50px;
  width: 50px;
}

.small-links li {
  display: inline;
  margin: 10px;
  align-content: center;
  padding-right: 20px;
  padding-top: 0px;
}

.small-links {
  padding-right: 20px;
}

/* #946c0A */
/* f2c256 */
